import React from 'react';
import './Loading.css';

const LoadingMain = () => {
    return (
        <div className="spinner-container">
            <div className="spinner"></div>
        </div>
    );
};

const Loading = () => {
    return (
        <div className="background">
            <div className="container">
                <div className="spinner"></div>
            </div>
        </div>
    );
};

export {LoadingMain, Loading};